export default {
  'Link channel': 'Vincular canal',
  Linked: 'Vinculados',
  Unlinked: 'No vinculados',
  'In process': 'En proceso',
  'In process help':
    'Ya comenzaste con el proceso de integrar un número de WhatsApp con Uniboxi. Te contactaremos en caso que necesitemos alguna acción de tu parte. Este proceso puede tomar de 5 a 15 días.',
  'Linked help': 'Vinculado',
  'Unlinked help': 'No vinculado',
  'Expired help': 'Expirado',
  'Deleted help': 'Eliminado',
  'Restore channel': 'Restaurar canal',
  'Relink channel': 'Revincular canal',
  'Relink channel help': '¿Está seguro que desea revincular este canal?',
  'Unlink channel': 'Desvincular canal',
  'Unlink channel help': '¿Está seguro que desea desvincula este canal?',
  Unlink_whatsapp:
    'Al eliminar la integración del número de WhatsApp {{number}}, estarás dejando ese número para siempre. Tienes 10 días desde este momento para solicitar su reintegración en caso que cambies de opinión.',
  'Delete channel': 'Eliminar canal',
  'Delete channel help': '¿Está seguro que desea eliminar este canal?',
  'Select the channel to integrate with Uniboxi': 'Seleccione el canal para integrar con Uniboxi.',
  'Link Facebook and Messenger': 'Vincular Facebook y Messenger',
  'Link Whatsapp': 'Vincular Whatsapp',
  'Link Instagram': 'Vincular Instagram',
  'Link Email': 'Vincular correo electrónico',
  'No integrations': 'Aún no hay canales integrados',
  'No integrations help':
    'Esta sección, le permitirá integrar disintos canales de comunicación con sus clientes, como Facebook Messenger, Whatsapp y otros.',
  'Whatsapp Linking Process': 'Proceso de Vinculación de Whatsapp',
  'In order to use WhatsApp from the platform, all businesses must go through a verification process between':
    'Para poder utilizar WhatsApp desde la plataforma, todos los negocios deben pasar por un proceso de verificación de entre',
  '10 to 15 business days': '10 a 15 días hábiles.',
  'If you are approved by WhatsApp, you will be assigned a': 'Si es aprobado por WhatsApp, se le asignará un',
  'New number in your country for your company': 'nuevo número en su país para su empresa.',
  'At the moment, your current number cannot be used': 'Por el momento, no se puede utilizar su número actual.',
  'Some cool features you will have': 'Algunas características geniales que tendrás',
  'Distribute all your WhatsApp messages among your sales or support representatives':
    'Distribuya todos sus mensajes de Whatsapp entre sus representantes de venta o soporte.',
  'Automate follow-up messages': 'Automatice mensajes de seguimiento',
  'Keep all your communication channels within Uniboxi': 'Mantenga todos sus canales de comunicación dentro de Uniboxi',
  'Begin integration process': 'Comenzar proceso de integración',
  'Please complete the following form. After sending the information, we will send you an email with the next steps to follow':
    'Por favor completa el siguiente formulario. Luego de enviada la información, te enviaremos un correo con los próximos pasos a seguir.',
  'Complete the form': 'Completar formulario',
  'If you have any questions during the process, please write to us at': 'Si tienes alguna duda durante el proceso, por favor escríbenos a',
  'The Whatsapp integration process has already started. Once you complete the form we will contact you':
    'El proceso de integración de Whatsapp ya comenzó. Una vez completes el formulario nos pondremos en contacto.',
  'Back to integrations': 'Volver a integraciones',
  'Integrations help':
    'Esta sección, le permitirá integrar distintos canales de comunicación con sus clientes, como Facebook Messenger, Whatsapp y otros.',
  Whatsapp_form_link: 'https://docs.google.com/forms/d/e/1FAIpQLSe5MxpRJ751k9L8INfJyjp_SHZPTdvh-k9-NQliob7xqAALTQ/viewform?usp=sf_link',
  '{{kind}} integration has failed': 'Integración de {{kind}} ha fallado',
  '{{kind}} integration has successful': 'Integración de {{kind}} exitosa',
  '{{kind}} integration has successful with warnings': 'Integración de {{kind}} exitosa con alertas',
  'The integration process was completed successfully and you are ready to start using this new channel.':
    'El proceso de integración finalizó con éxito y ya puede usar este nuevo canal.',
  'The integration process was completed with warnings.': 'El proceso de integración finalizó con alertas.',
  'The integration process has failed. Please try again.': 'El proceso de integración ha fallado. Por favor intente nuevamente.',
  'Integrate another channel': 'Integrar otro canal',
  'Your account doesnt have any channel linked to Uniboxi': 'Tu cuenta no tiene ningún canal integrado en Uniboxi.',
  'If you want to integrate Facebook Messenger or WhatsApp click on the button below to go to the Integrations page':
    'Si quieres integrar Facebook Messenger, Instagram o WhatsApp, dirígete a la página de Integraciones haciendo click en el botón de abajo.',
  'Add WhatsApp number': 'Agregar número de Whatsapp',
  'Cancel request': 'Cancelar solicitud',
  'Once you request a new WhatsApp number, our support team will contact you to help you activate it':
    'Una vez que solicites un nuevo número de WhatsApp, nuestro equipo de soporte te contactará para ayudarte a activarlo.',
  'Channel name': 'Nombre del canal',
  'Example sales, support, etc': 'Ejemplo: ventas, soporte, etc.',
  'Request number': 'Solicitar número',
  'Edit channel name': 'Editar nombre del canal',
  'before linking': 'Antes de vincular',
  'before restoring channel': 'Antes de restaurar canal',
  'before link Instagram': 'Verifique que su cuenta de Facebook se encuentre conectada con una cuenta de Instagram, para hacerlo, siga  ',
  before_link_instagram_url: 'https://uniboxi.com/blog/es/integracion-instagram-en-uniboxi/',
  'click here': 'click aquí',
  'note link instagram':
    '. Al vincular Instagram con Facebook, asegurarse de permitir acceso de mensajes de Instagram en Inbox de Facebook.',
  'Accounts not connected': 'Cuentas no conectadas',
  'Your Facebook account is not linked to an Instagram account. To do so please follow ':
    'Su cuenta de Facebook no se encuentra vinculada a una cuenta de Instagram. Para hacerlo por favor siga ',
  'these instructions': 'estas instrucciones',
  url_instagram_account_unlinked: 'https://www.facebook.com/help/1148909221857370',
  'Once the accounts are connected, go through the linking process again from Uniboxi.':
    '. Una vez que las cuentas estén conectadas, realice nuevamente el proceso de vinculación desde Uniboxi.',
  'Access to messages denied': 'Acceso a mensajes denegado',
  'Your Instagram account does not allow access to read messages. To do so please follow ':
    'Su cuenta de Instagram no permite acceder a la lectura de mensajes. Para hacerlo por favor ',
  url_access_to_messages_denied: 'https://uniboxi.com/blog/es/integracion-instagram-en-uniboxi/',
  'Once access to messages is enabled, perform the linking process again from Uniboxi.':
    '. Una vez habilitado el acceso a mensajes, realice nuevamente el proceso de vinculación desde Uniboxi.',
  'Waiting {{kind}} login': 'Esperando permisos de {{kind}} ',
  'Instagram account is not for business': 'Cuenta de Instagram no es de negocio',
  'Your Instagram account is not a business account. To convert it please follow ':
    'Tu cuenta de Instagram no es de negocio. Para convertirla por favor siga ',
  url_account_is_not_bussines: 'https://help.instagram.com/502981923235522?helpref=hc_fnav',
  'Once the account is an Instagram Business, perform the linking process again from Uniboxi.':
    'Una vez la cuenta sea de negocio, realice nuevamente el proceso de vinculación desde Uniboxi.',
  'API key required': 'API key obligatoria',
  'API key is too short': 'API key es muy corta',
  'Namespace is too short': 'Namespace es muy corto',
  Expired: 'Expirado',
  Finish: 'Finalizar',
  'In process help 2': 'Por favor obtener el API key desde la cuenta de <2>360 Dialog</2> y pegarla aquí.',
  'Enter API key': 'Ingresar API key',
  'The integration process will be done with our certified WhatsApp partner called 360 Dialog.':
    'El proceso de integración se realizará con nuestro partner certificado de WhatsApp llamado 360 Dialog.',
  'You must have your own phone number to integrate. It is possible to use landline numbers (recommended) or mobile numbers':
    'Debes tener tu propio número de teléfono para integrar. Es posible usar números de linea fija (recomendado) o móviles.',
  'Add API key': 'Agregar API key',
  'I will do it later': 'Lo haré más tarde',
  'If your number is already being used with WhatsApp, you have to delete that WhatsApp account from that mobile before you go through the integration process. This is done from the WhatsApp app (Settings > Account > Delete my account).':
    'Si tu número ya se está utilizando con WhatsApp, debes eliminar esa cuenta de WhatsApp de ese móvil antes de pasar por el proceso de integración. Esto se hace desde la aplicación WhatsApp (Configuración > Cuenta > Eliminar mi cuenta).',
  'This Facebook account cannot be integrated as it is associated with another company on Uniboxi. Once it has been unlinked, please try again.':
    'Esta cuenta de Messenger no se puede integrar ya que está asociada a otra empresa en Uniboxi. Una vez que haya sido desvinculada, por favor intentar nuevamente.',
  'This Instagram account cannot be integrated as it is associated with another company on Uniboxi. Once it has been unlinked, please try again.':
    'Esta cuenta de Instagram no se puede integrar ya que está asociada a otra empresa en Uniboxi. Una vez que haya sido desvinculada, por favor intentar nuevamente.',
  url_pricing: 'https://uniboxi.com/es/precios/',
  'To link a channel, sign up for one of our plans': 'Para vincular un canal, da de alta uno de nuestros planes.',
  'See plans': 'Ver planes',
  "Please don't close this page.": 'Por favor, no cerrar esta página.',
  'The following warnings were generated during the integration process:':
    'Los siguientes avisos fueron generados durante el proceso de integración:',
  'Please check the following information and try again.': 'Por favor, revise la siguiente información y vuelva a intentarlo.',
  'Your Facebook account does not have the necessary permission, check the permission of the pages or try with another admin user.':
    'No se han obtenido los permisos necesarios, comprueba el permiso de las páginas o inténtalo con otro usuario administrador.',
  'Validating Business WhatsApp account': 'Validando cuenta de WhatsApp de negocio, por favor esperar unos minutos.',
  Register: 'Registrar',
  'Register WhatsApp': 'Registrar WhatsApp',
  'Must be 6 digits': 'Debe ser de 6 dígitos',
  'Wrong pin': 'Pin incorrecto',
  'Whatsapp integration modal desired pin': 'El pin de autenticación de dos pasos puede ser cambiado en este <0>enlace</0>,',
  'The steps are:': 'Los pasos son:',
  '1. Go to settings': '1. Ir a configuración',
  '2. Click on "More"': '2. Hacer click en "Más"',
  '3.Click on two step verification': '3. Hacer click en "Verificación de dos pasos"',
  '4. Click on "Change Pin"': '4. Hacer click en "Cambiar pin"',
  '5. Enter the desired pin': '5. Ingresar el pin deseado',
  '6. Click on "Save"': '6. Hacer click en "Guardar"',
  'Limit exceeded must wait 72 hours': 'Límite excedido, debe esperar 72 horas',
  'All this process will be done in the Meta / WhatsApp environment. At the end of the process, you need to add a payment method directly in WhatsApp to pay for the use of the pre-approved templates and marketing messages.':
    'Todo este proceso se realizará en el ambiente de Meta / WhatsApp. Al final del proceso, es necesario agregar una forma de pago directamente en WhatsApp para pagar por el uso de las plantillas pre aprobadas y mensajes de marketing.',
  'Once the integration process is complete, you will be prompted to enter a PIN is the one you want for your WhatsApp account. This PIN will be used to identify your WhatsApp account in the Uniboxi system.': 'Una vez completado el proceso de integración, se le pedirá que introduzca el pin deseado para su cuenta de WhatsApp. Este pin se utilizará para identificar su cuenta de WhatsApp en el sistema Uniboxi.',
  'No payment method found. If you have already added one, please check again later': 'No se encontró un método de pago. Si ya agregó uno, por favor verifique nuevamente más tarde',
  'Add payment method': 'Agregar método de pago',
  'Check payment method': 'Verificar método de pago',
  'At the end of the process, you need to add a payment method directly in WhatsApp to pay for the use of the pre-approved templates and marketing messages.':
    'Al final del proceso, es necesario agregar una forma de pago directamente en WhatsApp para pagar por el uso de las plantillas pre aprobadas y mensajes de marketing.',
  'If you already have a payment method, please check it.': 'Si ya tienes un método de pago, por favor verifícalo.',
  'You need to add a payment method directly in WhatsApp to pay for the use of pre-approved templates and marketing messages.': 'Es necesario agregar un método de pago directamente en WhatsApp para pagar por el uso de las plantillas pre aprobadas y mensajes de marketing.',
};
