export default {
  'Initial conversation date': 'Initial conversation date',
  'Assigned user': 'Assigned user',
  'No user assigned': 'No user assigned',
  Change: 'Change',
  'Change owner': 'Change owner',
  'In property of': 'In property of',
  'Type Chat (Messenger, Whatsapp, etc)': 'Type Chat (Messenger, Whatsapp, etc)',
  'Time window error': 'Message not sent. Surpass the 24 hours limit.',
  'New field name': 'New field name',
  'Mark as read': 'Mark as read',
  'Mark as unread': 'Mark as unread',
  'Last message not sent': 'Last message not sent',
  'Sequence in progress': 'Sequence in progress',
  'Information fields': 'Information fields',
  'Add or modify information fields that users can fill in about customers':
    'Add or modify information fields that users can fill in about customers.',
  'Add or modify information field': 'Add or modify information field',
  'You can drag the fields to sort them': 'You can drag the fields to sort them.',
  'Has sended type': 'Has sended {{type}}',
  Actives: 'Active',
  Inactives: 'Inactives',
  'Manage fields': 'Manage fields',
  'Edit custom field': 'Edit custom field',
  'Deactivate custom field': 'Deactivate custom field',
  'Activate custom field': 'Activate custom field',
  'Delete custom field': 'Delete custom field',
  Delete_custom_help: 'Are you sure you want to delete this field? This action is not reversible.',
  'Delete custom field option': 'Delete option',
  Delete_custom_option_help: 'Are you sure you want to delete this option? This action is not reversible.',
  Sequence: 'Sequence',
  Started: 'started',
  'No tags have been assigned yet': 'No tags have been assigned yet',
  'Not assigned': '-',
  'This channel is unlinked': 'This channel is unlinked.',
  'Go to integrations': 'Go to integrations.',
  'Please select a conversation': 'Please select a conversation',
  'Delete note': 'Delete note',
  'Delete note help': 'Are you sure you want to delete this note?',
  'Use template': 'Use template',
  'Use sequence': 'Use sequence',
  'Launch sequence': 'Launch sequence',
  'Right now': 'Right now',
  Schedule: 'Schedule',
  Begin: 'Begin',
  'This message will be sent in': 'This message will be sent in',
  'Stop sequence': 'Stop sequence',
  Stop_sequence_help: 'Are you sure you want to stop this sequence? This action is not reversible.',
  'Begin sequence': 'Begin sequence in',
  in: 'in',
  hour: 'hour',
  hours: 'hours',
  minute: 'minute',
  minutes: 'minutes',
  'Unread messages': 'More unread messages',
  'Add a category to start building your board': 'Add a category to start building your board.',
  missing_permissions: 'Missing permissions',
  unauthorized_application: 'Message not sent. This channel is unlinked',
  unauthorized_application_help:
    'If you think this channel should be linked, please contact your company administrator to reinstate this channel from the Integrations page.',
  time_window_error: 'Message not sent. 24 hours have been exceeded.',
  time_window_error_help:
    'Once a customer has initiated or responded to a message in Whatsapp, the business has up to 24 hours to respond to them. After 24 hours from the last customers message, the business can not send them another message. (In accordance with Whatsapp Policies).',
  time_window_one_week_error: 'Message not sent. 7 days have been exceeded.',
  time_window_one_week_error_help:
    'Once a customer has initiated or replied to a message either on Messenger or Instagram, the business has up to 7 days to respond. (According to Facebook Messenger policies).',
  time_window_sequence_failure: 'The sequence failed because the 24h window ended',
  time_window_sequence_failure_help:
    'Once a customer has initiated or responded to a message in Messenger or Whatsapp, the business has up to 24 hours to respond to them. After 24 hours from the last customers message, the business can not send them another message. (In accordance with Facebook Messenger Policy).',
  max_whatsapp: 'The account has reached the limit of WhatsApp messages',
  max_whatsapp_help:
    "The account will continue receiving incoming WhatsApp messages. However, the messages won't be able to be answered until the the next billing period or when the account has been upgraded to a new plan.",
  'The channel is unlinked and you will not be able to send messages by the same':
    'The channel is unlinked and you will not be able to send messages by the same.',
  unknown_message_error: 'Message not sent. Please try again.',
  whatsapp_experimental_error: 'Message not sent.',
  whatsapp_experimental_error_help:
    'This number is part of a Facebook experiment and does not allow you to send pre-approved templates anymore. Please contact this person by another mean and ask them to write to you on WhatsApp so you can continue the conversation.',
  facebook_experiment: 'Facebook experiment',
  Attachment: 'Attachment',
  Attachments: 'Attachments',
  CustomClients_file_too_big: 'The file is larger than 10GB and cannot be sent. Please reduce its weight and try again.',
  Instagram_file_too_big: 'The file is larger than 4GB and cannot be sent. Please reduce its weight and try again.',
  Whatsapp_file_too_big: 'The file is larger than 16MB and cannot be sent. Please reduce its weight and try again.',
  Facebook_file_too_big: 'The file is larger than 25MB and cannot be sent. Please reduce its weight and try again.',
  'Invalid file type': 'Invalid file type for ',
  'File too big': 'File too big',
  'This account used all available WhatsApp pre-approved templates. Please talk to your admin to reload':
    'This account used all available WhatsApp pre-approved templates. Please talk to your admin to reload.',
  'Max characters exceeded': 'Max characters exceeded.',
  'Use pre-approved templates': 'Use pre-approved templates.',
  'Pre-approved template popper help':
    'Pre-approved templates can be used to send messages on WhatsApp after the 24-hour limit has expired from the last message from the client. According to WhatsApp policies.',
  InformationLastMessageError: 'Last message not sent',
  'You have not added any notes to this conversation yet': 'You have not added any notes to this conversation yet.',
  'Loading messages': 'Loading messages',
  'Manual conversation': 'Manual conversation',
  'Choose one of the following options to start a conversation': 'Choose one of the following options to start a conversation.',
  'New WhatsApp conversation': 'New WhatsApp conversation',
  'Send an e-mail': 'Send an e-mail',
  'Call contact': 'Call contact',
  wrongNumberErrorHelp: 'An error has occurred! The number entered is not correct and the message could not be sent.',
  'Change number': 'Change number',
  sticker_error: "Someone sent you a sticker but Uniboxi can't display it.",
  ephemeral_error:
    'Alguien te envió un mensaje que desaparece después de un tiempo. Lamentablemente no lo podemos mostrar por restricciones de WhatsApp.',
  unsupported_instagram_error: "Someone sent you a message with an item that Uniboxi can't display due to Instagram API restrictions.",
  unsupported_whatsapp_error: "Someone sent you a message with an item that Uniboxi can't display due to WhatsApp API restrictions.",
  'You have entered an incorrect WhatsApp number': 'You have entered an incorrect or invalid WhatsApp number.',
  number_already_contacted:
    'It is not possible to send a WhatsApp message since this number is already linked to another conversation. The owner is: {{assignedUserName}}. If you are the owner of that conversation, look it up on the main board or in Deleted conversations.',
  'Message read': 'Message read',
  'Message sent': 'Message sent',
  'Sending message': 'Sending message',
  'Message delivered': 'Message delivered',
  empty_fields_template: 'In order to use this template, you must have the following fields with information: ',
  '<firstName>': '<First name>',
  '<lastName>': '<Last name>',
  '<email>': '<Email>',
  '<number>': '<Phone number>',
  '<assignedUserName>': '<assigned user name>',
  '<assignedUserEmail>': '<assigned user email>',
  '<assignedUserPhone>': '<assigned user phone>',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  number: 'Phone number',
  assignedUserName: 'assigned user name',
  assignedUserEmail: 'assigned user email',
  assignedUserPhone: 'assigned user phone',
  empty_fields_secuence: 'In order to use this sequence, please add contact information for the following fields: ',
  reply_to: 'Answered your story',
  story_mention: 'Mentioned you in his story',
  'This message has been deleted.': 'This message has been deleted.',
  'Pre-approved templates can not be modified. If you need a different template, talk to your administrator.':
    'Pre-approved templates can not be modified. If you need a different template, talk to your administrator.',
  'Invalid Date Format': 'Invalid Date Format.',
  invalid_attachment_secuence: 'This sequence have a {{kind}} invalid file type.',
  'Recording audio...': 'Recording audio...',
  'Template Missing': 'This template is not yet available. Please wait 30 minutes and try again.',
  payment_issue: 'Message not sent. Issue with your whatsapp business account payment method.',
  payment_issue_help: 'Please check your WhatsApp business account payment method and try again.',
  'Give like': 'Give like',
  'Please allow the explorer to use your microphone.': 'Please allow the explorer to use your microphone.',
  'To record voice messages, you must allow Uniboxi to access the microphone in Chrome. To do this, click the icon':
    'To record voice messages, you must allow Uniboxi to access the microphone in Chrome. To do this, click the icon',
  'in the URL bar and choose "Always allow microphone access to app.uniboxi.com".':
    'in the URL bar and choose "Always allow microphone access to app.uniboxi.com".',
  Download: 'Download',
  'Copy text': 'Copy text',
  phoneChangedNewLead: ', to keep talking with them click here ',
  Image: 'Image',
  Video: 'Video',
  Audio: 'Audio',
  File: 'File',
  'v-card': 'Contact card',
  Story: 'Story',
  'Reply cannot be audios or attachments.': 'Reply cannot be audios or attachments.',
  Reply: 'Reply',
  'This contact belongs to another user of the account. To be able to send a message to this contact, you must be the assigned user of this card.':
    'This contact belongs to another user of the account. To be able to send a message to this contact, you must be the assigned user of this card.',
};
